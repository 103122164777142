import React, {Component} from 'react'
import {Launcher} from 'react-chat-window'


class ChatWindow extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      messageList: [],
      lastMeMessage: ''
    };
  }
 
  _onMessageWasSent(message) {
    this.setState({
      messageList: [...this.state.messageList, message],
      lastMeMessage: message.data.text
    })
    this._getReply(message)
  }

  _sendMessage(text) {
    if (text.length > 0) {
      this.setState({
        messageList: [...this.state.messageList, {
          author: 'them',
          type: 'text',
          data: { text }
        }]
      })
    }
  }

  _getReply = async message => {
    const response = await fetch(`https://esoterea-service.apps.deixis.online/bot/?session_id=${this.props.sessionId}&text=${encodeURIComponent(message.data.text)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    this._sendMessage(body.text)
  };
 
  render() {
    return (<div>
      <Launcher
        agentProfile={{
          teamName: 'Esoterea',
          imageUrl: 'assets/images/esoterea_64.png'
        }}
        onMessageWasSent={this._onMessageWasSent.bind(this)}
        messageList={this.state.messageList}
        mute={true}
      />
    </div>)
  }
}

export default ChatWindow;