import React from 'react';
import ChatWindow from './components/ChatWindow';
import './App.css';
import {v1 as uuid} from "uuid";

function App() {
  const sessionId = uuid();
  return (
    <div className="App">
      <h1>Esoterea</h1>
      <img alt="profile" src="assets/images/esoterea_256.png"/>
      <h2>Chatbot de Astrología en Español</h2>
      <ChatWindow sessionId={sessionId}/>
    </div>
  );
}

export default App;
